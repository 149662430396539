import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-loading-results',
  templateUrl: './loading-results.component.html',
  styleUrls: ['./loading-results.component.scss'],
  imports: [CommonModule, NgIf],
  standalone: true
})
export class LoadingResultsComponent implements OnInit {
  @Input() public loadingState: boolean;
  @Input() public horizontal = false;
  @Input() public resultType;
  // Supported Types: section, inline, icon-only, floating
  @Input() public type = 'icon-only';

  constructor() {}

  public ngOnInit() {}

}
