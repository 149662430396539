<div class="results-listing"
     *ngIf="loadingState === true; else noResults">
  <!-- Section Template -->
  <div class="section"
       *ngIf="loadingState === true && type === 'section'">
    <div class="results-listing__spinner"></div>
    <h4>Loading {{resultType}} ...</h4>
  </div>
  <!-- Inline Template -->
  <div class="section"
       [class.horizontal]="horizontal"
       *ngIf="loadingState === true && type === 'inline'">
    <div class="results-listing__spinner"></div>
    <h4>Loading {{resultType}} ...</h4>
  </div>
  <!-- Icon Only Template -->
  <div class="icon-only"
       *ngIf="loadingState === true && type === 'icon-only'">
    <div class="icon-only-text"
         *ngIf="resultType">{{resultType}}</div>
    <div class="icon-only__spinner"></div>
  </div>
  <!-- Floating Template -->
  <div class="floating-loading-container"
       *ngIf="loadingState === true && type === 'floating'">
    <div class="floating-loading ama-card--shadow">
      <p class="floating-loading-text">Loading {{resultType}} </p>
      <div class="results-listing__spinner"></div>
    </div>
  </div>
</div>
<ng-template #noResults>
  <div class="results-listing">
    <p class="large bold">
      <ng-content></ng-content>
    </p>
  </div>
</ng-template>
